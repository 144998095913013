body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

/* Header styles */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #101010;
  padding: 20px;
}

.grey-button {
  background: #777777;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  margin-right: 10px;
  border-radius: 5px;
}
.teal-button {
  background: #009688;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  margin-right: 10px;
  border-radius: 5px;
}

/* Search bar styles */
.search-bar {
  background: rgba(255, 255, 255, 0.7);
  border-radius: 30px;
  padding: 20px;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
}

.search-bar input {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  margin-bottom: 10px;
}

.tagline {
  color: #009688;
  font-weight: bold;
}

.background-video-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1; /* Place the video behind other content */
}

.background-video {
  min-width: 100%; /* Cover the entire viewport */
  min-height: 100%;
  object-fit: cover; /* Maintain aspect ratio and cover the container */
}
.contact-us {
  position: fixed;
  bottom: 10px;
  right: 20px;
}

.contact-us button {
  background-color: #009688;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: -10px;
}

.contact-us button:hover {
  background-color: #007a6d;
}