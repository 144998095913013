/* PropertyListing.css */
.property-listing {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding: 20px;
    gap: 20px;
    margin-top: 400px; /* Adjust the margin-top as needed */
  }
  
  .property {
    flex: 0 0 auto;
    width: 300px; /* Adjust the width as needed */
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    text-align: center;
    padding: 20px;
  }
  
  .property img {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
  }
  
  .property h3 {
    font-size: 1.5rem;
    margin: 10px 0;
  }
  
  .property p {
    font-size: 1rem;
    margin: 5px 0;
  }
  